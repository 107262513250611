import React, {FC, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

import {emailService} from 'api/services';
import PFButton from 'shared/components/PF-Button';
import PFInput from 'shared/components/PF-Input';
import PFPopup from 'shared/components/PF-Popup';
import {useAppDispatch, useAppSelector} from 'store';
import {InviteAdvisorModalActions} from 'store/actions';
import {selectIsOpenInviteAdvisorModal, selectSourceInviteAdvisorModal} from 'store/selectors';
import {Amplitude, generateValidator, hints, ToastDispatcher, validations} from 'utils';

import './style.scss';

type PropsTypes = {
	className?: string;
};

const InviteNewAdviserModal: FC<PropsTypes> = ({className}) => {
	const dispatch = useAppDispatch();
	const isOpen = useAppSelector(selectIsOpenInviteAdvisorModal);
	const source = useAppSelector(selectSourceInviteAdvisorModal);
	const {formState, handleSubmit, watch, trigger, register} = useForm({
		mode: 'onChange',
	});

	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		const subscription = watch(async (data, {name}) => {
			if (name === 'email') {
				await trigger('email');
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, trigger]);

	const submitForm = async valueObj => {
		const body = {email: valueObj?.email};

		try {
			setIsSubmitting(true);
			await emailService.inviteAdvisor(body);

			Amplitude.track('Invited_New_Advisor', {
				source,
			});
			Amplitude.incrementUserProperty('Total_Sent_Invitation');

			ToastDispatcher.success('Invitation sent');
			dispatch(InviteAdvisorModalActions.setClose());
		} catch (e) {
			ToastDispatcher.error('Failed to sent invitation. Please try again');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<PFPopup
			isShow={isOpen}
			handleClose={() => dispatch(InviteAdvisorModalActions.setClose())}
			isShowFooter={false}
			submitBtnText="Send"
			primaryVariant="primary"
			isCloseButton
			isShowCancelBtn={false}
			title="Invite a new advisor"
			subtitle="Use an email to invite."
			onSubmit={() => false}
			className={{root: `inviteAdvisorModal ${className || ''}`}}>
			<form onSubmit={handleSubmit(submitForm)} className="inviteAdvisorModal__form">
				<div className="col-12 email">
					<PFInput
						{...register('email', {
							validate: generateValidator(hints.email.required, validations.email),
						})}
						// @ts-ignore
						showAsterisk={false}
						label="Email"
						placeholder="Email"
						type="email"
						defaultValue=""
						error={formState.errors?.email?.message && formState.errors?.email?.message}
					/>
				</div>
				<div className="footer">
					<PFButton
						disabled={!formState.isValid || isSubmitting}
						onClick={() => false}
						type="submit">
						Send
					</PFButton>
				</div>
			</form>
		</PFPopup>
	);
};

export default InviteNewAdviserModal;
