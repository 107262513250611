import React, {FC} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {isPossiblePhoneNumber} from 'libphonenumber-js';
import PFInput from 'shared/components/PF-Input';
import PFPhoneInput from 'shared/components/PF-PhoneInput';
import PFSelect from 'shared/components/PF-Select';
import {useLocations} from 'shared/hooks/profile';
import {AdvisorType} from 'types';
import {generateValidator, hints, isPhoneEmpty, renderLocation, validations} from 'utils';

import FormInputKeys from '../EditProfileForm/constants';

type PropsTypes = {
	formProps: UseFormReturn;
	advisor?: AdvisorType;
};

const ContactInformationFormBloc: FC<PropsTypes> = ({advisor, formProps}) => {
	const fetchLocations = useLocations();

	const halfWidthClass = 'col-lg-6 mb-4';
	const fullWidthClass = 'col-12 mb-4';

	const loadLocationTokenOptions = async (Query, loadedOptions) => {
		// this is our custom load options, we're returning the values from the API call
		const {
			body: options,
			count,
			currentCount,
			// @ts-ignore
		} = await fetchLocations({
			Query,
			skip: loadedOptions.length,
		});
		return {
			options,
			hasMore: currentCount !== 0 && count > options.length + loadedOptions.length,
		};
	};

	return (
		<>
			<div className={`${fullWidthClass} locationToken`}>
				<PFSelect
					{...formProps.register(FormInputKeys.location, {required: true})}
					// @ts-ignore
					defaultValue={
						formProps.getValues(FormInputKeys.location) ||
						(advisor && advisor?.location)
					}
					showAsterisk
					label="Location"
					placeholder="Enter your location"
					disabled={false}
					isMulti={false}
					blurInputOnSelect
					closeMenuOnSelect
					isAsync
					onChange={value => {
						formProps.setValue(FormInputKeys.location, value);
					}}
					getOptionValue={item => item.token}
					loadOptions={loadLocationTokenOptions}
					formatOptionLabel={option => renderLocation(option)}
					defaultOptions
					error={
						formProps.formState.errors[FormInputKeys.location]?.message &&
						formProps.formState.errors[FormInputKeys.location]?.message
					}
				/>
			</div>
			<div className={`${halfWidthClass} contactEmail`}>
				<PFInput
					{...formProps.register(FormInputKeys.contactEmail, {
						validate: generateValidator(hints.email.required, validations.email),
					})}
					// @ts-ignore
					showAsterisk
					label="Contact email"
					placeholder="example@mail.com"
					type="email"
					hint={<div className="inputHint">This email will be public</div>}
					defaultValue={
						formProps.getValues(FormInputKeys.contactEmail) ||
						(advisor && (advisor.contactEmail || advisor.email))
					}
					error={
						formProps.formState.errors[FormInputKeys.contactEmail]?.message &&
						formProps.formState.errors[FormInputKeys.contactEmail]?.message
					}
				/>
			</div>
			<div className={`${halfWidthClass} phoneNumber`}>
				<PFPhoneInput
					name={FormInputKeys.phoneNumber}
					control={formProps.control}
					showAsterisk={false}
					label="Phone Number"
					defaultValue={advisor ? advisor?.phoneNumber : ''}
					error={
						formProps.formState.errors[FormInputKeys.phoneNumber]?.message &&
						formProps.formState.errors[FormInputKeys.phoneNumber]?.message
					}
					labelSufix={false}
					variant={false}
					className=""
					hint={false}
					rules={{
						required: false,
						validate: value => {
							if (value?.countryCode) {
								if (isPhoneEmpty(value)) return true; // Ph# is not required, so if it's empty, then it's valid
								const k = isPossiblePhoneNumber(
									// This uses a google library to check if the ph# the user is entering is valid or invalid
									value?.value,
									value?.countryCode?.toUpperCase(),
								);
								return k || 'Please enter a valid phone number.'; // If valid, we're good, otherwise show the error message
							}
							return true;
						},
					}}
				/>
			</div>
			<div className={`${fullWidthClass} webSite`}>
				<PFInput
					{...formProps.register(FormInputKeys.webSite, {
						validate: generateValidator(false, validations.textFields.webSite),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Professional Website Link"
					placeholder="https://example.com"
					defaultValue={advisor ? advisor?.webSite : ''}
					error={
						formProps.formState.errors[FormInputKeys.webSite]?.message &&
						formProps.formState.errors[FormInputKeys.webSite]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} calendlyLink`}>
				<PFInput
					{...formProps.register(FormInputKeys.calendlyLink, {
						validate: generateValidator(false, validations.textFields.contactLink),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Calendar Link"
					placeholder="Paste your calendar link here"
					defaultValue={advisor ? advisor?.calendlyLink : ''}
					error={
						formProps.formState.errors[FormInputKeys.calendlyLink]?.message &&
						formProps.formState.errors[FormInputKeys.calendlyLink]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} facebook`}>
				<PFInput
					{...formProps.register(FormInputKeys.facebook, {
						validate: generateValidator(false, validations.textFields.contactLink),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Facebook Link"
					placeholder="facebook.com/your_nickname"
					defaultValue={advisor ? advisor?.facebook : ''}
					error={
						formProps.formState.errors[FormInputKeys.facebook]?.message &&
						formProps.formState.errors[FormInputKeys.facebook]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} linkedin`}>
				<PFInput
					{...formProps.register(FormInputKeys.linkedin, {
						validate: generateValidator(false, validations.textFields.contactLink),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="LinkedIn Link"
					placeholder="linkedin.com/your_nickname"
					defaultValue={advisor ? advisor?.linkedin : ''}
					error={
						formProps.formState.errors[FormInputKeys.linkedin]?.message &&
						formProps.formState.errors[FormInputKeys.linkedin]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} instagram`}>
				<PFInput
					{...formProps.register(FormInputKeys.instagram, {
						validate: generateValidator(false, validations.textFields.contactLink),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Instagram Link"
					placeholder="instagram.com/your_nickname"
					defaultValue={advisor ? advisor?.instagram : ''}
					error={
						formProps.formState.errors[FormInputKeys.instagram]?.message &&
						formProps.formState.errors[FormInputKeys.instagram]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} youtube`}>
				<PFInput
					{...formProps.register(FormInputKeys.youtube, {
						validate: generateValidator(false, validations.textFields.contactLink),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Youtube Link"
					placeholder="youtube.com/channel"
					defaultValue={advisor ? advisor?.youtube : ''}
					error={
						formProps.formState.errors[FormInputKeys.youtube]?.message &&
						formProps.formState.errors[FormInputKeys.youtube]?.message
					}
				/>
			</div>
			<div className={`${fullWidthClass} twitter`}>
				<PFInput
					{...formProps.register(FormInputKeys.twitter, {
						validate: generateValidator(false, validations.textFields.contactLink),
					})}
					// @ts-ignore
					showAsterisk={false}
					label="Twitter Link"
					placeholder="twitter.com/your_nickname"
					defaultValue={advisor ? advisor?.twitter : ''}
					error={
						formProps.formState.errors[FormInputKeys.twitter]?.message &&
						formProps.formState.errors[FormInputKeys.twitter]?.message
					}
				/>
			</div>
		</>
	);
};

export default ContactInformationFormBloc;
