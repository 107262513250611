import {PRICE_TABLE_DATA, PricingData} from '../PriceTable';

export const getPeriodName = (isAnnual: boolean, opposite: boolean): string => {
	if (isAnnual) {
		if (opposite) {
			return 'monthly';
		}
		return 'annual';
	}

	if (opposite) {
		return 'annual';
	}

	return 'monthly';
};

export const getCurrentDateFromInternet = async (): Promise<Date> => {
	try {
		const response = await fetch('https://worldtimeapi.org/api/ip');
		const data = await response.json();
		return new Date(data.datetime);
	} catch (error) {
		console.error('Error fetching the current date from the internet:', error);
		return new Date(); // Fallback to local date if the API fails
	}
};

export const getCurrentPricing = async (): Promise<PricingData | undefined> => {
	const currentDate = await getCurrentDateFromInternet();
	return (
		PRICE_TABLE_DATA.find(
			data => currentDate >= data.startDate && currentDate <= data.endDate,
		) || PRICE_TABLE_DATA[PRICE_TABLE_DATA.length - 1]
	);
};
