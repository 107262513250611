export const HASHTAG_TYPES = {
	PROVINCES_LICENSED: {
		value: 1,
		label: 'Provinces Licensed/Served',
		key: 'provincesLicensed',
	},
	SERVICES_OFFERED: {
		value: 2,
		label: 'Services Offered',
		key: 'servicesOffered',
	},
	// CLIENTS_SERVED_TYPES: {
	// 	value: 3,
	// 	label: 'Type of clients served',
	// 	key: 'clientTypesServed',
	// },
	/* TODO: sent to the server and received from the server as "Expertise". On the front side, the field is called "Niche". */
	NICHE: {
		value: 4,
		label: 'Niche',
		key: 'niche',
	},
};

export const SPECIALIZATION_OPTIONS_TYPES = [
	{
		type: 1,
		name: 'Investment Advisor',
	},
	{
		type: 2,
		name: 'Life and Health Insurance Advisor',
	},
	{
		type: 15,
		name: 'Home and Auto Insurance Advisor',
	},
	{
		type: 3,
		name: 'Residential Mortgage Advisor',
	},
	{
		type: 14,
		name: 'Commercial Mortgage Advisor',
	},
	{
		type: 10,
		name: 'Financial Advisor',
	},
	{
		type: 4,
		name: 'Accountant',
	},
	{
		type: 5,
		name: 'Bookkeeper',
	},
	{
		type: 6,
		name: 'Lawyer',
	},
	{
		type: 7,
		name: 'Realtor',
	},
	{
		type: 9,
		name: 'Financial Planner',
	},
	{
		type: 11,
		name: 'Restructuring Specialist',
	},
	{
		type: 12,
		name: 'Money Coach',
	},
	{
		type: 13,
		name: 'Group Benefits Advisor',
	},
];

export const INACTIVE_REASON_OPTIONS_TYPES = [
	{
		type: 1,
		name: 'Parental leave',
	},
	{
		type: 2,
		name: 'Vacation',
	},
	{
		type: 3,
		name: 'Sick leave',
	},
];
